<template>
    <ui-data-table 
        :p-headers="headers"
        p-title="Usuários"
        p-read-link="user.read.one"
        p-create-link="user.create"
        p-name="users"
    />
</template>
<script>

import {uiDataTable} from '@/components/ui'
export default {
    name: 'vwUserRead',
    components: {
        uiDataTable  
    },
    data(){
        return {
            headers: [{
                text: 'id',
                value: 'id'
            }, {
                text: 'Usuario',
                value: 'login'
            }, {
                text: 'Cadastrado',
                value: 'criado_em'
            }, {
                text: 'Ações', 
                value: 'actions', 
                sortable: false
            }],
        }
    },
}
</script>